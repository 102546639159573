import { breakpoints, colors, size, typography } from '@argo/principles';
import { Heading } from '@argo/ui-elements';
import styled from '@emotion/styled';
import React, { useState, forwardRef, useCallback } from 'react';

import dynamic from 'next/dynamic';
import LazyComponent from '../../../components/LazyComponent';
import { getResizedImageUrl } from '../../../utilities';

const Buttons = dynamic(() => import('./Buttons'), { ssr: false });

const getHeadingFontFamily = (isKbb) => `
    ${isKbb ? '\'Montserrat-ExtraBold\', \'Montserrat ExtraBold\', \'Montserrat\', sans-serif' : '\'Roboto-Bold\', \'Roboto Bold\', \'Roboto\', sans-serif !important'} ;
`;

const normalFontFamily = (isKbb) => `
    ${isKbb ? '\'Open Sans\', \'sans-serif\', \'Tohoma\', Arial' : '\'Roboto-Regular\', \'Roboto\', \'Roboto\', sans-serif'} ;
`;

// ------------------------------------card container------------------------------------
const DealerCard = styled.div`
    display: flex;
    flex-direction: column;
    transition: transform 0.4s ease-in-out;
    position: relative;
    max-width: 100%;
    min-height: 231px;
    padding: 22px;    
    background-color: ${colors.primary.white};
    margin-bottom: 15px;
    border: ${((props) => (props.isKbb ? '0' : `1px ${colors.neutral.doveGray} solid`))};
    cursor: ${((props) => (props.hasDealerDetail ? 'pointer' : 'default'))};
    @media (min-width: ${breakpoints.lg + 1}px) {
        flex-direction: row;
        padding: 20px;
    }
    @media (max-width: ${breakpoints.md + 205}px) {
        min-height: 533px;
    }    
`;

// ------------------------------------card body------------------------------------
const CardBody = styled.div`
    background-color: ${colors.primary.white};
    box-sizing: border-box;
    max-width: 100%;
    min-height: 191px;
    border-radius: 0 0 ${size.micro}px ${size.micro}px;
    display: flex;
    flex-direction: row;
    flex-grow: 1;
    @media (max-width: ${breakpoints.md + 205}px) {
        flex-direction: column;
        justify-content: space-between;
        align-items: center;
        min-height: 489px;
    }
`;

// -------------------------------card dealer image---------------------------------------------
const slideShowWidthLg = '225px';
const slideShowHeightLg = '185px';
const slideShowHeightMd = '240px';

const DealerImageContainer = styled.div`
    width: ${slideShowWidthLg};
    min-height: ${slideShowHeightLg};

    @media (max-width: ${breakpoints.lg}px) {
        height: ${slideShowHeightLg};
        width: ${slideShowWidthLg};
  }

    @media (max-width: ${breakpoints.md + 205}px) {
        height: ${slideShowHeightMd};
        width: 100%;
    }
`;

const DealerImage = styled.img`
  object-fit: cover;
  background-size: cover;
  width: ${slideShowWidthLg};
  height: ${slideShowHeightLg};
  @media (max-width: ${breakpoints.lg}px) {
    height: ${slideShowHeightLg};
    width: ${slideShowWidthLg};
  }
  @media (max-width: ${breakpoints.md + 205}px) {
    height: ${slideShowHeightMd};
    width: 100%;
  }
`;

// --------------------------------dealer details section--------------------------------
const DealerDetails = styled.div`
    display: flex;
    flex-direction: column;
    padding-left: 20px;
    width: 100%;
    min-height: 191px;
    @media (max-width: ${breakpoints.md + 205}px) {
        padding-left: 0px;
        min-height: 233px;
    }
`;

// ------------------------------------card - address------------------------------------
const AddressFlexBox = styled.div`
    ${typography.types.body};
    font-family: ${(props) => normalFontFamily(props.isKbb)};
    min-height: 24px;
`;

// ------------------------------------card - phonenumber/mileage------------------------------------
const PhoneNumberMilageWrapper = styled.div`
    order: 2;
    display: flex;
    justify-content: space-between;
    flex-grow: 2;
    font-family: ${(props) => normalFontFamily(props.isKbb)};
    min-height: 53px;

    @media (max-width: ${breakpoints.md + 205}px) {
        min-height: 21px;
    };
`;

const Mileage = styled.span`
    color: ${colors.primary.black};
    margin: 0;
`;

// ------------------------------------card - header------------------------------------
const StyledHeading = styled(Heading)`
    margin: 0 0 ${size.micro}px 0;
    @media (max-width: ${breakpoints.lg}px) {
        margin-top: ${size.sm}px;
    }
    font-family: ${(props) => getHeadingFontFamily(props.isKbb)};
`;

// ------------------------------------card - dealer ratings widget------------------------------------
const DealerRatings = styled.div`
    min-height: 30px;
    width: 100%;
`;

// ------------------------------------card - buttons------------------------------------
const ButtonWrapper = styled.div`
    order: 3;
    margin-right: 9px;
    margin-top: 20px;
    @media (min-width: ${breakpoints.lg + 1}px) {
        min-height: 48px;
    }
    @media (max-width: ${breakpoints.md + 205}px) {
        padding-top: 16px;
        margin-right: 0px;
        min-height: 94px;
    }
`;

const NO_IMG = 'https://file.kbb.com/kbb/images/icons/no-image-te/420x278-new.png';

// ------------------------------------DEALER CARD------------------------------------
export const DealerCardContainer = forwardRef((props, ref) => {
    const {
        isKbb,
        name,
        primaryPhotoUrl,
        dealerRatingsLink,
        primaryButtonProps,
        secondaryButtonProps,
        tertiaryButtonProps,
        handleDealerCardClick,
        addressDetails = { fullAddressText: '123 Main St' },
        phone,
        hasDealerDetail,
        isMobile = false,
        distanceFromSearch,
        isShowVisitSite,
    } = props;

    const roundedDistance = Math.round(distanceFromSearch);

    const getCardImage = useCallback(() => {
        if (!primaryPhotoUrl || primaryPhotoUrl.length === 0) return NO_IMG;
        return getResizedImageUrl(primaryPhotoUrl, 690, 240);
    }, [primaryPhotoUrl]);

    const [cardImageUrl, setCardImageUrl] = useState(getCardImage(primaryPhotoUrl));

    const getFallbackCardImage = useCallback(() => {
        const isResizedImage = cardImageUrl !== NO_IMG && cardImageUrl !== primaryPhotoUrl;
        const isOriginImage = cardImageUrl !== NO_IMG && cardImageUrl === primaryPhotoUrl;

        if (isResizedImage) {
            // eslint-disable-next-line no-console
            console.log(`media-management-api - resize service failed at ${cardImageUrl}. Use origin image instead`);
            return primaryPhotoUrl;
        }

        if (isOriginImage) {
            // eslint-disable-next-line no-console
            console.log(`media-management-api - origin image failed at ${cardImageUrl}. Use kbb fallback image instead`);
            return NO_IMG;
        }
    }, [primaryPhotoUrl, cardImageUrl]);

    const onDealerCardImageError = useCallback(() => {
        const fallbackCardImageUrl = getFallbackCardImage(primaryPhotoUrl, cardImageUrl);
        if (fallbackCardImageUrl) setCardImageUrl(fallbackCardImageUrl);
    }, [cardImageUrl, primaryPhotoUrl, getFallbackCardImage]);

    const getDealerHeading = useCallback(() => (
        <>
            <StyledHeading
                headingTag="h2"
                headingName="SubsectionHeadingExtra"
                enableTitleCase
                isKbb={isKbb}
            >
                {name}
            </StyledHeading>
        </>
    ), [isKbb, name]);

    // this function will take a 10 digit phone number and format it like so (XXX) XXX-XXXX
    // if the input cannot be formatted, return the input as is
    const renderPhoneNumber = useCallback((phoneNumber) => {
        let newNumber = phoneNumber;
        if (phoneNumber) {
        // strip out all non digit characters from phone
            newNumber = phoneNumber.toString().replace(/\D/g, '');

            if (newNumber.length === 10) {
            // format 10 digit number to (XXX) XXX-XXXX
                newNumber = phoneNumber.toString().replace(/(\d{3})(\d{3})(\d{4})/, '($1) $2-$3');
                return newNumber; // return new formatted phone number if format was complete
            }
        }
        return phoneNumber; // otherwise return the original phone number
    }, []);

    const renderAddress = useCallback(() => {
        if (!addressDetails || !addressDetails.fullAddressText) return;
        let addressLineOne = addressDetails.fullAddressText.split(',')[0];
        let addressLineTwo = addressDetails.fullAddressText.replace(addressDetails.trimmedAddress + ',', '').trim();
        // If the street address of the dealer is longer than 25 characters, we'll need to split the street address
        if (isMobile) {
            if (addressLineOne.length > 25) {
                let addressLineBreak = 24;
                while (addressDetails.fullAddressText[addressLineBreak] !== ' ' && addressLineBreak >= 0) addressLineBreak--;
                addressLineTwo = addressLineOne.slice(addressLineBreak).trim() + ', ' + addressLineTwo;
                addressLineOne = addressLineOne.slice(0, addressLineBreak);
            }
            if (addressLineTwo.length > 25) {
                addressLineTwo = addressLineTwo.slice(0, 23).trim() + '...';
            }
        }

        if (isMobile) {
            return (
                <span title={addressLineTwo.includes('...') ? addressDetails.fullAddressText : null}>
                    {addressLineOne}
                    <br />
                    {addressLineTwo}
                </span>
            );
        }

        return (
            <span title={addressLineTwo.includes('...') ? addressDetails.fullAddressText : null}>
                {addressDetails.fullAddressText}
            </span>
        );
    }, [addressDetails, isMobile]);

    return (
        <DealerCard
            tabIndex="0"
            data-testid="ref-intersection"
            data-cy="dealerCard"
            onClick={handleDealerCardClick}
            ref={ref}
            isKbb={isKbb}
            hasDealerDetail={hasDealerDetail}
        >
            <CardBody>
                <DealerImageContainer>
                    <DealerImage
                        data-testid="dealerImageTag"
                        src={cardImageUrl}
                        alt={name}
                        onError={onDealerCardImageError}
                    />
                </DealerImageContainer>
                <DealerDetails data-testid="details">
                    {getDealerHeading()}
                    {hasDealerDetail && (
                        <DealerRatings data-testid="dealerRatings">
                            <LazyComponent
                                height={size.md}
                                width="235"
                            >
                                <iframe
                                    frameBorder="0"
                                    height={size.md}
                                    width="235"
                                    scrolling="no"
                                    src={dealerRatingsLink}
                                    title="KBB Dealer Ratings"
                                />
                            </LazyComponent>
                        </DealerRatings>
                    )}
                    <AddressFlexBox
                        data-testid="address"
                        isKbb={isKbb}
                    >
                        {renderAddress(addressDetails, isMobile)}
                    </AddressFlexBox>
                    <PhoneNumberMilageWrapper isKbb={isKbb}>
                        <span data-testid="phoneNumber">
                            {renderPhoneNumber(phone)}
                        </span>
                        <Mileage data-testid="mileageContent">
                            {`${roundedDistance.toString() === '1'
                                ? '1 mile away'
                                : `${roundedDistance} miles away`
                                }`}
                        </Mileage>
                    </PhoneNumberMilageWrapper>
                    <ButtonWrapper data-cy="buttonWrapper">
                        <Buttons
                            data-cmp="ownerScheduleServiceCTA"
                            primaryButtonProps={primaryButtonProps}
                            secondaryButtonProps={secondaryButtonProps}
                            tertiaryButtonProps={tertiaryButtonProps}
                            isKbb={isKbb}
                            isShowVisitSite={isShowVisitSite}
                        />
                    </ButtonWrapper>
                </DealerDetails>
            </CardBody>
        </DealerCard>
    );
});

export default React.memo(DealerCardContainer);
