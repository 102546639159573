import React from 'react';

const DealerCardPlaceHolder = () => {
    const renderImageSection = () => (
        <div className="col-xs-3 padding-0 left-panel">
            <div className="skeleton__image_portrait skeleton__placeholder" />
        </div>
    );

    const renderContactInfo = () => (
        <div className="dealer-card-contact display-flex justify-content-between margin-bottom-4 padding-right-4">
            <div className="dealer-card-phone skeleton__subtext skeleton__placeholder" />
            <div className="dealer-card-distance skeleton__subtext skeleton__placeholder" />
        </div>
    );

    const renderButtons = () => (
        <div className="dealer-card-button-section display-flex justify-content-end padding-right-4">
            <div className="visit-site-btn skeleton__subtext skeleton__placeholder" />
            <div className="view-car-btn skeleton__subtext skeleton__placeholder" />
        </div>
    );

    const renderDetailsSection = () => (
        <div className="col-xs-9 padding-0 right-panel margin-left-5 margin-top-1">
            <div className="dealer-card-heading skeleton__placeholder" />
            <div className="dealer-card-rating skeleton__subtext skeleton__placeholder" />
            <div className="dealer-card-address skeleton__subtext skeleton__placeholder" />
            {renderContactInfo()}
            {renderButtons()}
        </div>
    );

    return (
        <div data-cmp="dealerCardListingPlaceholder">
            <div className="dealer-card-listing cursor-pointer panel panel-default">
                <div className="row dealer-card skeleton__listing skeleton__loading display-flex">
                    {renderImageSection()}
                    {renderDetailsSection()}
                </div>
            </div>
        </div>
    );
};

export default DealerCardPlaceHolder;
