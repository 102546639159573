import { sendPage, sendEvent, getConsumerMetadata } from 'reaxl-analytics';
import { useStore } from 'react-redux';
import { get as _get } from '../utilities/common';
import {
    dealerFiltersDuck,
    dealerPaginationDuck,
    dealerResultsDuck,
} from '../ducks';

export default function useCreateFindDealerPageEvent() {
    const store = useStore();
    return async ({
        isInitialLoad = false,
        event,
    } = {}) => {
        const state = store.getState();
        const dataIsland = _get(state, 'birf.pageData', {});
        const consumerMetadata = await getConsumerMetadata();
        const data = {
            pageEvents: ['fyd_srl_reload'],
            consumerMetadata,
        };
        sendPage({
            data,
            dataIsland,
            domainKey: 'dealersearch',
            isInitialLoad,
            filterDuck: dealerFiltersDuck,
            paginationDuck: dealerPaginationDuck,
            resultsDuck: dealerResultsDuck,
            event,
        });
        sendEvent({
            name: 'search',
            type: 'search',
            dataIsland,
        });
    };
}
