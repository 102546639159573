import React, { memo, useCallback } from 'react';
import dynamic from 'next/dynamic';
import styled from '@emotion/styled';
import { get as _get } from '../../utilities/common';
import { connect } from 'react-redux';
import { useBrand } from 'reaxl-brand';
import { kbbBrand } from '../../constants';
import { dealerMetadataDuck } from '../../ducks';

const FindADealerFilterLocationModalContainer = dynamic(() => import('./FindADealerFilterLocationModalContainer'), { ssr: false });

const SpanNear = styled.span`
    display: block;
    font-size: 14px;
    font-weight: 500;
    letter-spacing: 0.5px;
    margin-top: 5px;
    margin-bottom: 10px;
    @media only screen and (max-width: 768px) {
        margin-bottom: 2px;
    }
`;

const FindADealerHeadingContainer = memo(({ heading = '' }) => {
    const { isBrand } = useBrand();
    const isKbb = isBrand(kbbBrand);

    const renderHeading = useCallback(() => {
        if (isKbb) {
            if (heading.includes('Dealer in')) {
                return (
                    <>
                        {heading.split('Dealer in')[0]}
                        {' '}
                        Dealers
                        <SpanNear>
                            near
                            <span className="text-bold">{`${heading.split('Dealer in')[1]}`}</span>
                            <FindADealerFilterLocationModalContainer />
                        </SpanNear>
                    </>
                );
            }
        } else if (heading.includes('All Dealers in')) {
            return (
                <>
                    {heading.replace('All Dealers in', 'All Dealers Near')}
                    <FindADealerFilterLocationModalContainer />
                </>
            );
        }

        return (
            <>
                {heading}
                <FindADealerFilterLocationModalContainer />
            </>
        );
    }, [isKbb, heading]);

    return (
        <div className={`heading-container ${isKbb ? 'kbb-heading-container' : 'at-heading-container'}`}>
            <h1
                id="main-heading-text"
                data-cmp="heading"
                className={`${isKbb ? 'kbb-main-heading' : 'at-main-heading'}`}
            >
                {renderHeading(isKbb, heading)}
            </h1>
        </div>

    );
});
function mapStateToProps(state) {
    const dealerMetadata = dealerMetadataDuck.selectors.getDuckState(state);
    return {
        heading: _get(dealerMetadata, 'heading', ''),
    };
}

export default connect(mapStateToProps)(FindADealerHeadingContainer);
