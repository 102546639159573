import React, { memo, useCallback, useMemo } from 'react';
import { connect } from 'react-redux';
import { mapDispatchToActionProp } from '@atc/modular-redux';
import { useRouter } from 'next/router';
import { get as _get } from '../../utilities/common';
import { sendClick } from 'reaxl-analytics';
import { Select } from 'reaxl';

import {
    dealerFiltersDuck,
    dealerPaginationDuck,
    dealerSortDuck,
    dealerSetMakeDuck,
    dealerResultsDuck,
    dealerCrawlPathDuck,
} from '../../ducks';
import { rebuildFindADealerPage } from '../../actions';

import { allMakeValue } from '../../constants';
import { transformsMake } from '../../utilities';

const DealerResultControlsContainer = memo(({
    actions,
    resultCount,
    makeList,
    makeValue,
    makeCodes,
}) => {
    const router = useRouter();
    const allMakeLabel = 'All Makes';
    let makeOptions = [];

    const newFilter = useMemo(() => ({
        collapsed: true,
        label: 'Make',
        name: 'makeCode',
        options: [],
    }), []);

    if (makeList.length > 0) {
        makeOptions = makeList.map((item) => ({
            value: item.makeName,
            label: item.makeName,
        }));
        makeOptions = [{ value: allMakeValue, label: allMakeLabel }, ...makeOptions];
        newFilter.options = makeCodes.map((mc) => ({ value: mc.code, label: mc.name }));
        const allMake = makeOptions.find((m) => m.label.toLowerCase() === makeValue.toLowerCase());
        if (allMake) {
            makeValue = allMake.value;
        }
    }

    const handleOnChanged = useCallback((sortType) => {
        makeValue = sortType;
        if ((makeCodes.length !== 0) && sortType) {
            if (sortType !== allMakeValue) {
                makeValue = makeCodes.find((mc) => mc.name.toLowerCase() === sortType.toLowerCase()).code;
            }
            const newEvent = { type: 'checkbox', checked: sortType !== allMakeValue, target: { value: makeValue } };
            actions.applyFilterChange(newEvent, newFilter);
            actions.setMake(sortType);
        }
    }, []);

    const handleSortChange = useCallback(async (event) => {
        const sortType = _get(event, 'target.value', null) || event;
        handleOnChanged(sortType, actions, newFilter, makeCodes);
        sendClick('sortDropDownClick', event, { sortType });
        actions.resetPagination();

        // Update the page location
        await actions.rebuildFindADealerPage(router.push);
    }, [actions, makeCodes, newFilter, router.push, handleOnChanged]);

    const makeData = {
        options: [...makeOptions],
        value: makeValue,
    };

    return (
        <Select
            id="makeSelector"
            labelProps=""
            onChange={handleSortChange}
            className="justify-content-md-end"
            data-cmp="filteredResultsSortControl"
            key="filteredResultsSortControl"
            {...makeData}
        />
    );
});

function mapStateToProps(state) {
    const crawlPathData = dealerCrawlPathDuck.selectors.getDuckState(state);
    return {
        resultCount: dealerResultsDuck.selectors.getResultsCount(state),
        sortValue: dealerSortDuck.selectors.getDuckState(state),
        makeCodes: _get(crawlPathData, 'makeCodes', []),
        makeList: _get(crawlPathData, 'makeList', []),
        makeValue: transformsMake(dealerSetMakeDuck.selectors.getDuckState(state)),
    };
}

const mapDispatchToProps = mapDispatchToActionProp({
    setSort: dealerSortDuck.creators.setSort,
    setMake: dealerSetMakeDuck.creators.setMake,
    showFilters: dealerFiltersDuck.creators.showFilters,
    applyFilterChange: dealerFiltersDuck.creators.applyFilterChange,
    resetPagination: dealerPaginationDuck.creators.resetPagination,
    rebuildFindADealerPage,
});

export default connect(mapStateToProps, mapDispatchToProps)(DealerResultControlsContainer);
